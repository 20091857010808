import React from "react";
import Card from "../../Components/Card/Card";
import Logo1 from "../../Logo/OB/Mela.png";
import Logo2 from "../../Logo/OB/Manasala.png";
import Logo3 from "../../Logo/OB/ctc.jpeg";
import Logo4 from "../../Logo/OB/Bye.png";
import Logo5 from "../../Logo/OB/Whatsapp.jpeg";
import Logo6 from "../../Logo/OB/Amongus.jpeg";
import "./NonTechEvents.css";
import Layout from "../../Components/Layout/Layout";

const NonTechEvents = () => {
  return (
    <Layout>
      <div className="container">
      <Card
        imageUrl={Logo1}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSf7DcQ75cG-gQSl2WKjpJwsFN6xjF_4-VTeDmZDiL86rI3W0Q/viewform?usp=header"
        title="Symposium Registration"
        name="ATHUKUM MELA"
      />
      <Card
        imageUrl={Logo2}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSdqW4b43N6sWs4q9LYCKeOyAXdvRGOCGka9epx-oMjKC4a1gw/viewform?usp=header"
        title="Symposium Registration"
        name="MANASILAYO"
      />
      <Card 
        imageUrl={Logo3}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSd0CE815Gcj1DFUCGNk_Aiz-mECnh384KSsElLVOD9755DkDQ/viewform?usp=header"
        title="Symposium Registration"
        name="CRACK THE CODE"
      />
      <Card
        imageUrl={Logo4}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSdXjW7X1L9UTgkJn7zBvRoJikkcL89Pqt5IIDujvttHU7hR8Q/viewform?usp=header"
        title="Symposium Registration"
        name="BEAT THE CLOCK"
      />
       <Card
        imageUrl={Logo5}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSf97jdvlcCdF5qwlg0opcMZRsq9U9Zyc6sk1wMMIu9abnWHOg/viewform?usp=header"
        title="Symposium Registration"
        name ="MELODY MANIA"
      />
       <Card
        imageUrl={Logo6}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSePLpxwopQwbTwBvOzqKQ8Xbo3DPAs1PZmBlusQwmDQfIt4FA/viewform?usp=header"
        title="Symposium Registration"
        name="AMONG US"
      />
    </div>
    </Layout>
    
  );
};

export default NonTechEvents;
