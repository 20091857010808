import React, { useState } from "react";
import Cards from "../../Components/Cards/Cards";
import TechLogo1 from "../../Logo/OB/Paper.png";
import TechLogo2 from "../../Logo/OB/Poster.png";
import TechLogo3 from "../../Logo/OB/College.png";
import TechLogo4 from "../../Logo/OB/Spark.png";
import TechLogo5 from "../../Logo/OB/Watt.png";
import TechLogo6 from "../../Logo/OB/Wire.png";
import NonTechLogo1 from "../../Logo/OB/Mela.png";
import NonTechLogo2 from "../../Logo/OB/Manasala.png";
import NonTechLogo3 from "../../Logo/OB/Hi.png";
import NonTechLogo4 from "../../Logo/OB/Bye.png";
import NonTechLogo5 from "../../Logo/OB/Whatsapp.jpeg";
import NonTechLogo6 from "../../Logo/OB/Among.png";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Import arrow icons
import "./EventsPage.css"; // Create a new CSS file for styling
import Layout from "../../Components/Layout/Layout";

const EventsPage = () => {
  const [eventType, setEventType] = useState("tech"); // State to manage event type

  // Tech events data
  const techEvents = [
    {
      imageUrl: TechLogo1,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "PAPER PRESENTATION",
    },
    {
      imageUrl: TechLogo2,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "CIRC-I-TUDE",
    },
    {
      imageUrl: TechLogo3,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "CHARGE AND CLIMB",
    },
    {
      imageUrl: TechLogo4,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "THE SPARK OF GENIUS",
    },
    {
      imageUrl: TechLogo5,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "WATT-A-CONNECTION",
    },
    {
      imageUrl: TechLogo6,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "WIRED-UP",
    },
  ];

  // Non-tech events data
  const nonTechEvents = [
    {
      imageUrl: NonTechLogo1,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "ATHUKUM MELA",
    },
    {
      imageUrl: NonTechLogo2,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "MANASILAYO",
    },
    {
      imageUrl: NonTechLogo3,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "CRACK THE CODE",
    },
    {
      imageUrl: NonTechLogo4,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "BEAT THE CLOCK",
    },
    {
      imageUrl: NonTechLogo5,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "MELODY MANIA",
    },
    {
      imageUrl: NonTechLogo6,
      formLink: "https://forms.gle/yourGoogleFormLink",
      title: "Symposium Registration",
      name: "AMONG US",
    },
  ];

  // Function to toggle between tech and non-tech events
  const toggleEventType = () => {
    setEventType((prev) => (prev === "tech" ? "non-tech" : "tech"));
  };

  return (
    <Layout>
      <div className="events-page">
        {/* Toggle button with arrows */}
        <div className="event-toggle">
          <button onClick={toggleEventType} className="toggle-button">
            {eventType === "tech" ? (
              <>
                <FaArrowLeft className="arrow-icon" /> Non-Tech Events
              </>
            ) : (
              <>
                Tech Events <FaArrowRight className="arrow-icon" />
              </>
            )}
          </button>
        </div>

        {/* Display events based on the selected type */}
        <div className="container">
          {eventType === "tech"
            ? techEvents.map((event, index) => (
                <Cards
                  key={index}
                  imageUrl={event.imageUrl}
                  formLink={event.formLink}
                  title={event.title}
                  name={event.name}
                />
              ))
            : nonTechEvents.map((event, index) => (
                <Cards
                  key={index}
                  imageUrl={event.imageUrl}
                  formLink={event.formLink}
                  title={event.title}
                  name={event.name}
                />
              ))}
        </div>
      </div>
    </Layout>
  );
};

export default EventsPage;