import React from "react";
import Card from "../../Components/Card/Card";
import Logo1 from "../../Logo/OB/pp.png";
import Logo2 from "../../Logo/OB/Poster.png";
import Logo3 from "../../Logo/OB/College.png";
import Logo4 from "../../Logo/OB/Spark.png";
import Logo5 from "../../Logo/OB/Watt.png";
import Logo6 from "../../Logo/OB/Wire.png";
import "./TechEvents.css";
import Layout from "../../Components/Layout/Layout";

const TechEvents = () => {
  return (
    <Layout>
      <div className="container">
      <Card
        imageUrl={Logo1}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSfHmmZ9q_rgeIsp1j-Aq5grrj-eevOpjCpjUss6HhJ7TTcvAA/viewform"
        title="Symposium Registration"
        name="PAPER PRESENTATION"
      />
      <Card
        imageUrl={Logo2}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSf7Pe0PMnGfUbx_hobztu5uQkv1dha4lpVZqXMtwtYSLjTjqg/viewform?usp=header"
        title="Symposium Registration"
        name="CIRC-I-TUDE"
      />
      <Card
        imageUrl={Logo3}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSdKSHreQXH3rELxQNvBZVT9dzKr5atv2TddnpS56WMoSb-hUQ/viewform?usp=header"
        title="Symposium Registration"
        name="CHARGE AND CLIMB"
      />
      <Card
        imageUrl={Logo4}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSduOtvoVQ8mHZeRA0w7GWHZ3AzZXqlMSo0WgOnw1dcFujJLQQ/viewform?usp=header"
        title="Symposium Registration"
        name="THE SPARK OF GENIUS"
      />
        <Card
        imageUrl={Logo5}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSesC8FgCWgd_ofUSttD6oQ6iacICV6b4ean7PWEV4RgpGUcqQ/viewform?usp=header"
        title="Symposium Registration"
        name="WATT-A-CONNECTION"
      />
      <Card
        imageUrl={Logo6}
        formLink="https://docs.google.com/forms/d/e/1FAIpQLSe95eGYf45x6lzr_mvv9lldJOtCJyDQXntWzq6AkP5Xw3td3Q/viewform?usp=header"
        title="Symposium Registration"
        name="WIRED-UP"
      />
    </div>
    </Layout>
    
  );
};

export default TechEvents;
